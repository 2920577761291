import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import { A } from './homeStyledComponents';
import { margins, fontSizes, colors, mobileThresholdPixels } from '../styledComponents';
import { Title3 } from '../../components/home/v2/StyledComponents';
import maduraLogo from '../../assets/partners/madura.png';
import degresLogo from '../../assets/partners/85degres.png';
import admiseLogo from '../../assets/partners/admise_paris.png';
import apcLogo from '../../assets/partners/apc.png';
import artumescoLogo from '../../assets/partners/artumes&co.png';
import hastLogo from '../../assets/partners/hast_paris.png';
import isabelMarantLogo from '../../assets/partners/Isabel-Marant.png';
import jessicaChoayLogo from '../../assets/partners/jessica_choay.png';
import laureDeSagazanLogo from '../../assets/partners/Laure_de_Sagazan.svg';
import luzLogo from '../../assets/partners/luz.png';
import stephanieWolffLogo from '../../assets/partners/stephanie_wolff.png';
import magazellesLogo from '../../assets/partners/magazelles.svg';
import violetteSauvageLogo from '../../assets/partners/violette_sauvage.png';
import carolinaRitzlerLogo from '../../assets/partners/CarolinaRitzler.png';
import karlaOttoLogo from '../../assets/partners/karla_otto.png';
import lexceptionLogo from '../../assets/partners/l_exception.png';
import lesArmoiresDeParisLogo from '../../assets/partners/les_armoires_de_Paris.png';
import sezaneLogo from '../../assets/partners/sezane.png';
import unerobeunsoirLogo from '../../assets/partners/unerobeunsoir.png';
import elisechalminLogo from '../../assets/partners/elisechalmin.png';
import bourrienneLogo from '../../assets/partners/BourrienneParisX.png';

const Title1 = styled(Title3.withComponent('h1'))`text-align: center;`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 5px;
  }
`;

const CallToAction = styled.div`
  padding: ${margins.l} 0px;
  align-self: center;
  color: ${colors.navy};
  font-size: ${fontSizes.xl};
  font-weight: 300;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const Img = styled.img`
  max-height: 200px;
  height: auto;
  min-width: 150px;
  max-width: 300px;
  margin: ${margins.l};

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m};
  }
`;

const PartnersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
`;

const partners = [
  { caption: 'Madura, retouches d’ourlets de rideaux', img: maduraLogo, link: 'https://www.madura.fr/fr_fr/' },
  { caption: 'A.P.C., retouches et ourlets sur jeans', img: apcLogo, link: 'https://www.apc.fr/frfr/' },
  { caption: 'Isabel Marant, retouches et ourlets sur robes',
    img: isabelMarantLogo,
    link: 'https://www.isabelmarant.com/fr' },
  { caption: 'Magazelles, changement doublure et zip', img: magazellesLogo, link: 'https://magazelles.com/' },
  { caption: 'Laure de Sagazan, retouche de robe de mariée',
    img: laureDeSagazanLogo,
    link: 'https://www.lauredesagazan.fr/' },
  { caption: 'Violette Sauvage, vide-dressing', img: violetteSauvageLogo, link: 'http://www.violettesauvage.fr/' },
  { caption: 'Admise Paris, retouches sur tailleur', img: admiseLogo, link: 'https://admiseparis.com/' },
  { caption: '85° retouches de vêtement', img: degresLogo, link: 'http://85paris.com/' },
  { caption: 'Artumès & Co cintrage de jupe', img: artumescoLogo, link: 'http://www.artumesandco.com/' },
  { caption: 'Hast Paris, chemise sur mesure', img: hastLogo, link: 'https://www.hast.fr/fr/' },
  { caption: 'Jessica Choay, retouche à domicile', img: jessicaChoayLogo, link: 'http://www.jessicachoay.com/' },
  { caption: 'Luz maillots de bain', img: luzLogo, link: 'http://www.jessicachoay.com/' },
  { caption: 'Stephanie Wolff service à domicile',
    img: stephanieWolffLogo,
    link: 'http://www.stephaniewolffparis.com/' },
  { caption: 'Carolina Ritzler couturier Paris',
    img: carolinaRitzlerLogo,
    link: 'https://www.carolinaritzler.com/fr' },
  { caption: 'Karla Otto atelier retouches',
    img: karlaOttoLogo,
    link: 'http://karlaotto.com/' },
  { caption: 'L‘Exception service de retouches Aix Metz Marseille',
    img: lexceptionLogo,
    link: 'https://www.lexception.com/' },
  { caption: 'Les armoires de Paris retoucherie',
    img: lesArmoiresDeParisLogo,
    link: 'https://www.les-armoires-de-paris.com/' },
  { caption: 'Sézane retoucheur Paris',
    img: sezaneLogo,
    link: 'http://www.sezane.com/fr' },
  { caption: '1Robe1soir Paris retouche à domicile ouverte le dimanche',
    img: unerobeunsoirLogo,
    link: 'https://1robepour1soir.com/fr_FR/' },
  { caption: 'Elise Chalmin broderie t-shirt et pull',
    img: elisechalminLogo,
    link: 'https://elisechalmin.com/' },
  { caption: 'Bourrienne Paris X chemise cintrage',
    img: bourrienneLogo,
    link: 'https://www.bourrienne.com/' },
];

const Partners = ({ pressLink }) => (
  <Container>
    <Title1>Ils nous font confiance</Title1>

    <PartnersContainer>
      {partners.map(partner =>
        <Img src={partner.img} alt={partner.caption} key={partner.caption} />,
      )}
    </PartnersContainer>

    {pressLink ?
      <Link
        to={routesMap.PressPage.url} style={{ flex: 1, textAlign: 'center' }}
        onClick={() => trackEvent('click', 'presse_partenaires')}
      >
        <A>voir aussi nos parutions dans la presse et les blogs</A>
      </Link> :
      <Link
        to={routesMap.WhyBecomePartner.url} style={{ flex: 1, textAlign: 'center' }}
        onClick={() => trackEvent('click', 'pourquoi-partenaire-tilli_partenaires')}
      >
        <A>Pourquoi choisir Tilli comme partenaire retouches ?</A>
      </Link>
    }

    <CallToAction>
      Vous êtes une marque ?<br />
      Contacter Beryl au 06.11.10.19.27 ou par mail bdelabouchere@tilli.fr
    </CallToAction>
  </Container>
);

Partners.propTypes = {
  pressLink: PropTypes.bool,
};

Partners.defaultProps = {
  pressLink: false,
};

export default Partners;
